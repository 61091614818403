@import "../fonts/Sk-Modernist/Sk-Modernist.css";
@import "../fonts/Gilroy/Gilroy.css";
@import "./globals.scss";

* {
  margin: 0;
  padding: 0;
  font-family: "Gilroy";
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: white !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
}
