.container {
  background: #2c2c2c;
  margin-top: auto;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  p {
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: white;
  }
}
