.container {
  display: flex;
  justify-content: center;
}

.innerContainer {
  width: 100%;
  max-width: 1184px;
  padding: 0 24px;
}
