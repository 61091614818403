@import "./globals.scss";

.dashboard {
  display: flex;
  height: 100vh;
  width: 100%;
  .pane {
    width: 311px;
    background: #303a63;
    display: flex;
    flex-direction: column;
    @include tablet-large {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      transition: 0.2s;
      &.closed {
        transform: translateX(-100%);
      }
      z-index: 5;
    }
  }
  .content {
    flex: 1;
    overflow-y: auto;
    height: 100%;
    background: #343d64;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #424c73;
    }
    &::-webkit-scrollbar-track {
      background: #293255;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #606ca1;
    }
    @include tablet-large {
      padding: 10px;
    }
    .children {
      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #fff;
        margin: 10px 0;
      }
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  margin-left: 50px;
  padding: 42px 0;
  cursor: pointer;
  .text {
    margin-left: 12px;
  }
  h1 {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: bold;
    font-family: Sk-Modernist;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  .item {
    display: flex;
    align-items: center;
    padding: 18px 30px;
    cursor: pointer;
    border-right: 0px solid #e92169;
    transition: 0.5s;
    p {
      margin-left: 30px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
    &:hover {
      background: #293255;
    }
    &.active {
      background: rgba(231, 56, 119, 0.25);
      border-right: 8px solid #e92169;
    }
  }
}

.nav {
  display: none;
  @include tablet-large {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    padding: 0;
    margin-left: 0;
  }
}

.logout {
  margin-top: auto;
  padding: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-left: 20px;
    color: #fff;
  }
}

.burger {
  display: none;
  cursor: pointer;
  background: #e7ecff;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  @include tablet-large {
    display: grid;
    place-items: center;
  }
}

.helper {
  background: #343d64;
  color: white;
  overflow-x: hidden;
  width: 328w;
  transition: max-width 0.5s;
  @include phone-large {
    display: none;
  }
  @include tablet-large {
    width: 40vw;
  }
  .helperInner {
    width: 28vw;
    max-width: 500px;
    padding: 60px 40px;
    @include tablet-large {
      width: 40vw;
    }
  }
  &.closed {
    max-width: 0;
  }
  &.open {
    max-width: 500px;
  }
  .head {
    display: flex;
    justify-content: space-between;
    .hello {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
    .greet {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }
    .close {
      cursor: pointer;
      background: #424c73;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
    }
  }
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      .dp {
        position: absolute;
        width: 111px;
        height: 111px;
        background-color: #fff;
        border-radius: 100px;
        border: 6px solid #343d64;
        overflow: hidden;
        display: grid;
        place-items: center;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .details {
      .name {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
      }
      .joined {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        margin-top: 3px;
      }
      .edit {
        display: flex;
        align-items: center;
        background: #444e78;
        border-radius: 99px;
        border: none;
        padding: 9px 23px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        margin-top: 16px;
        cursor: pointer;
        p {
          margin-left: 12px;
        }
      }
    }
  }
}
