@import "../globals.scss";

.container {
  border-radius: 10px;
  font-weight: bold;
  font-size: 17px;
  border: none;
  cursor: pointer;
  align-items: center;
  transition: 0.2s;
  user-select: none;
  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.m {
  padding: 14px 37px;
}

.s {
  padding: 8px 14px;
}

.primary.filled {
  background: $primary;
  color: #fff;
  border: 1px solid $primary;
  &:hover {
    background: #303a5b;
  }
}
.primary.outlined {
  background: transparent;
  color: $primary;
  border: 1px solid $primary;
  &:hover {
    background-color: $primary;
    color: white;
  }
}
.primary:active {
  background: #303a5b !important;
}

.secondary.filled {
  background: $secondary;
  color: #fff;
  border: 1px solid $secondary;
  &:hover {
    background: #d70f57;
  }
}
.secondary.outlined {
  background: transparent;
  color: $secondary;
  border: 1px solid $secondary;
  &:hover {
    background-color: $secondary;
    color: white;
  }
}
.secondary:active {
  background: #b80646 !important;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
