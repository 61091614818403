.backdrop {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.562);
}

.container {
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  overflow: visible;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  max-height: calc(100vh - 40px);
  // min-height: 500px;
}

.innercontainer {
  padding: 30px 40px 50px 40px;
  width: 100%;
  overflow-y: auto;
  position: relative;
  flex: 1;
}

.close {
  // position: absolute;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -40px;
}

.component {
  margin: 0 auto 15px auto;
  max-width: 800px;
}
