@import "../globals.scss";

.container {
  background: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  transition: 0.2s;
  @include tablet {
    padding: 20px 0;
  }
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  .text {
    margin-left: 12px;
  }
  h1 {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $primary;
    font-weight: bold;
    font-family: Sk-Modernist;
  }
}

.nav {
  .innerNav {
    display: flex;
    align-items: center;
    @include Gap(5px);
    .links {
      display: flex;
      align-items: center;
    }
    .navLink {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #474747;
      cursor: pointer;
      padding: 21px 16px 27px;
      margin-top: 10px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      transition: 0.2s;
      &::after {
        content: "";
        background-color: #a8a8a8;
        height: 4px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 0;
        border-radius: 2px;
        width: calc(100% - 56px);
        transition: 0.2s;
        opacity: 0;
      }
      &:hover {
        background-color: #f9f9f9;
        &::after {
          opacity: 1;
        }
      }
      &.active {
        &::after {
          opacity: 1;
          background-color: #414c72;
        }
      }
    }
    button {
      margin-left: 10px;
    }
  }
}
.phone {
  display: flex;
  @include Gap(9px);
  align-items: center;
}

.phoneLink {
  text-decoration: none;
  p {
    margin-left: 12px;
  }
}

.phoneLink:hover {
  text-decoration: none;
}

.burger {
  display: none;
  cursor: pointer;
  background: #e7ecff;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  @include tablet {
    display: grid;
    place-items: center;
  }
}

.cart {
  display: none;
  cursor: pointer;
  @include tablet {
    display: grid;
    place-items: center;
  }
}

.blue {
  background: #34416b;
  h1,
  .nav p {
    color: white !important;
  }
  .nav {
    button {
      background-color: white;
      color: #34416b;
    }
    .navLink {
      &.active::after {
        background-color: white;
      }
      &:hover {
        background-color: #303b61 !important;
        &::after {
          background-color: white;
        }
      }
    }
  }
}

.dp {
  height: 34px;
  min-width: 34px;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.user {
  position: relative;
  .view {
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(192, 192, 192, 0.25);
    border-radius: 100px;
    padding: 8px;
    cursor: pointer;
    .arrow {
      margin: 0 8px;
    }
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #2c3966;
    }
  }
  .dropdown {
    position: absolute;
    top: 100%;
    width: 300px;
    right: 0;
    margin-top: 8px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 13%);
    border-radius: 15px;
    z-index: 5;
    &.closed {
      display: none;
    }
    .item {
      padding: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 10px;
      .icon {
        border-radius: 8.74817px;
        width: 52.49px;
        height: 52.49px;
        display: grid;
        place-items: center;
        margin-right: 16px;
      }
      &:nth-child(1) {
        .icon {
          background: #f3f6ff;
        }
        &:hover {
          background: #f9faff;
        }
      }
      &:nth-child(2) {
        .icon {
          background: #fff1f6;
        }
        &:hover {
          background: #fffafc;
        }
      }
    }
  }
}

.lone {
  display: none;
}

.nav {
  @include tablet {
    transition: 0.2s;
    overflow-y: auto;
    position: fixed;
    background-color: #2c3862;
    left: 0;
    width: calc(100vw - 90px);
    bottom: 0;
    top: 0;
    // z-index: 5;
    &.closed {
      transform: translateX(-100%);
    }
    .innerNav {
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: unset;
      padding: 30px;
      padding-top: 70px;
      &.out {
        .button {
          margin-bottom: 50px;
        }
      }
      .dp {
        width: 76.97px;
        height: 76.97px;
        position: relative;
        display: grid;
        place-items: center;
        background-color: white;
      }
      .lone {
        margin-bottom: 20px;
      }
      .dropdown {
        align-items: unset;
        display: flex;
        flex-direction: column-reverse;
        position: unset;
        justify-content: flex-start;
        box-shadow: none;
        background-color: transparent;
        &.closed {
          display: flex;
        }
        .icon {
          display: none !important;
        }
      }
      .links {
        align-items: unset;
        flex-direction: column;
        p {
          color: #fff;
          background: #3c4a79;
          padding: unset;
        }
      }
      .user {
        .view {
          background-color: transparent;
          flex-direction: column;
          align-items: flex-start;
          box-shadow: none;
          span {
            color: white;
            margin: 16px 0 5px 0;
          }
        }
        .arrow {
          display: none;
        }
      }
      .dropdown {
        align-items: stretch;
        width: 100%;
      }
      .item {
        all: unset;
        background: #3c4a79 !important;
        padding: 17px 20px !important;
        color: white !important;
        font-weight: bold;
      }
      .button {
        background-color: transparent !important;
        border: 2px solid #ffffff !important;
        border-radius: 10px;
        align-self: start;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #ffffff !important;
        padding: 15px 50px !important;
        &:hover {
          cursor: pointer;
        }
      }
      .phoneLink {
        display: none;
      }
      .dropdown {
        padding: 0;
      }
      .navLink,
      .item:first-child {
        padding: 17px 20px !important;
        margin: 12px 0;
        border-radius: 12px;
        &:hover {
          background-color: #3c4a79;
        }
        &::after {
          display: none;
        }
      }
      .item:first-child {
        margin-top: 48px;
      }
    }
  }
}
